import axios from './axios';
import {BASE_URL} from '../constants';
import {CreateTripRequest, Trip, ListTripsResponse, GetTripDetailsResponse,DeleteTripsRequest} from "@sumo/types"

/**
 * Calls the API to retrieve the list of users .
 * @returns a list of users
 */
 export const createTrip = async (body: CreateTripRequest): Promise<Trip> => {
	const response = await axios.post(`${BASE_URL}/v1/trips`, body);
	return response.data;
};


/**
 * Retrieves the list of trips
 * @returns A list of trips
 */
export const listTrips = async (): Promise<ListTripsResponse> => {
	const response = await axios.get(`${BASE_URL}/v1/trips`);
	return response.data;
};

/**
 * Retrieves the detail of a trip from the dynamo db table and the map from an s3
 * @returns The details and map from a trip
 */
export const getTripDetail = async (tripId: string): Promise<GetTripDetailsResponse> => {
	const response = await axios.get(`${BASE_URL}/v1/trips/${tripId}`);
	return response.data;
};

/**
 * Deletes trips by their ids
 * @param names Array of trip ids to delete
 * @returns Array of deleted trip names
 */
export const deleteTrips = async (tripIds: string[]): Promise<string[]> => {
	const request : DeleteTripsRequest = {tripIds}
	const response = await axios.delete(`${BASE_URL}/v1/trips`, {data: request});
	return response.data.tripIds;
};

/**
 * Create Trip multiple trips from CSV
 * @param trips Array of trip to create
 * @returns Array of deleted trip names
 */
export const createTripFromCSV = async (trips: CreateTripRequest[]) => {
	await axios.post(`${BASE_URL}/v1/tripimport`, trips);
};
