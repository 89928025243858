import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import Papa from 'papaparse';
import {CreateTripRequest, Vehicle} from '@sumo/types';
import {useSnackbar} from '@easerill/pixida-group-ui';
import {User} from '../../redux/Auth';
import {createTripFromCSV} from '../../api/trip';

export type CreateTripCsvProps = {
	vehicles: Vehicle[];
	availableUsers: User[];
};

const CsvFileUploadTripCreation= ({vehicles, availableUsers}: CreateTripCsvProps) => {
	const {triggerSnackbar} = useSnackbar();

	/**
	 * Calls the API to create new trips from the uploaded csv
	 * @param trips The data of the trips
	 */
	const createTripApi = (trips: CreateTripRequest[]) => {
		createTripFromCSV(trips)
			.then(() => {
				triggerSnackbar(
					'Die Fahrten werden erstellt, das kann einige Sekunden dauern. Bitte laden Sie die Seite in Kürze neu, um die Fahrt anzuzeigen.',
					'success'
				);
			})
			.catch((err) => {
				triggerSnackbar('Es gab einen Fehler beim Erstellen der Fahrten.', 'error');
			});
	};

	const formatTimeUTC = (date: string, time: string): string => {
		const [day, month, year] = date.split('.');
		const standardizedDate = `${year}-${month}-${day}`;

		const dateTimeString = `${standardizedDate}T${time}:00`;
		const parsedDate = new Date(dateTimeString);

		if (isNaN(parsedDate.getTime())) {
			throw new Error(`Ungültiges Datum oder Uhrzeit: ${dateTimeString}`);
		}

		return parsedDate.toISOString();
	};

	const requiredColumns = [
		'Datum',
		'Linie',
		'Kurs',
		'Beginn',
		'Umlaufnummer',
		'Haltestelle Dienstbeginn',
		'Kurs Ende',
		'Kurs Ende',
		'Haltestelle Dienstende',
		'Nummer des Dienstes',
		'Dienstnummer',
		'Name',
		'Fahrzeugnummer'
	];

	const validateCsvRows = (rows: any[]) => {
		for (const [index, row] of rows.entries()) {
			for (const column of requiredColumns) {
				if (!row[column]) {
					throw new Error(
						`Validierungsfehler: Fehlender oder leerer Wert in Spalte "${column}" in Zeile ${
							index + 1
						}.`
					);
				}
			}
		}
	};

	const addVehicleDetailsForTrip = (trip: CreateTripRequest): CreateTripRequest => {
		const vehicle = vehicles.find((v) => v.number === trip.assignedVehicle);
		if (!vehicle) {
			throw new Error(
				`Kein Fahrzeug verfügbar für die Fahrzeugnummer: ${trip.assignedVehicle}}`
			);
		}
		return {
			...trip,
			vehicleSerial: vehicle.serial,
			customerId: vehicle.customerId
		};
	};

	const addUserDetailsForTrip = (trip: CreateTripRequest): CreateTripRequest => {
		// The "Dienstnummer" will be used as the driver name from the customer
		const user = availableUsers.find((u) => u.name === trip.driverName);
		if (!user) {
			throw new Error(`Kein Benutzer verfügbar für Dienstnummer: ${trip.assignedVehicle}}`);
		}
		return {
			...trip,
			driverId: user.username || ''
		};
	};

  const addMissingInformation = (trips: CreateTripRequest[]): CreateTripRequest[] => {
    const filtered_trips = trips
      .map((trip) => {
        try {
          // Add vehicle and user details to each trip
          const tripWithVehicle = addVehicleDetailsForTrip(trip);
          const tripWithUser = addUserDetailsForTrip(tripWithVehicle);
          return tripWithUser;
        } catch (error: any) {
          triggerSnackbar(
            `Fahrt mit Fahrzeug "${trip.assignedVehicle}" und Fahrer "${trip.driverName}" übersprungen: ${error.message}`, 
            'info'
          );
          return null;
        }
      })
      .filter((trip): trip is CreateTripRequest => trip !== null); // Filter out null values
  
    // Check if the filtered trips array is empty
    if (filtered_trips.length === 0) {
      throw new Error(`Keine gültigen Trips vorhanden`);
    }
  
    return filtered_trips;
  };

	const parseCsvToTrips = (csvData: string): CreateTripRequest[] => {
		try {
		  const rows = Papa.parse(csvData, {
			header: true,
			skipEmptyLines: true,
			delimiter: ';',
		  }).data;
	  
		  validateCsvRows(rows);
	  
		  return rows.map((row: any) => {
			// Use `Kurs Ende' is duplicated in the excel. that why we try the duplication 'Kurs Ende_1' and the original to check which one fits the time format.
			const kursEndeTime = row['Kurs Ende_1']?.match(/^\d{2}:\d{2}$/)
			  ? row['Kurs Ende_1']
			  : row['Kurs Ende'];
	  
			if (!kursEndeTime || !kursEndeTime.match(/^\d{2}:\d{2}$/)) {
			  throw new Error(
				`Ungültige Zeitinformation für 'Kurs Ende' oder 'Kurs Ende_1' in Zeile mit Fahrzeugnummer ${
				  row['Fahrzeugnummer'] || 'unbekannt'
				}`
			  );
			}
	  
			return {
			  driverId: 'to be filled',
			  startTime: formatTimeUTC(row['Datum'], row['Beginn']),
			  endTime: formatTimeUTC(row['Datum'], kursEndeTime), // use the valid end time
			  driverName: row['Dienstnummer'] || '',
			  name: `Linie ${row['Linie'] || ''}, Kurs ${row['Kurs'] || ''}`,
			  vehicleSerial: 'to be filled',
			  assignedVehicle: row['Fahrzeugnummer'] || '',
			  customerId: 'to be filled',
			};
		  });
		} catch (error: any) {
		  throw new Error(`Fehler beim Parsen der CSV-Datei. ${error.message}`);
		}
	  };
	  

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			if (acceptedFiles.length === 0) {
				triggerSnackbar(
					'Das hochgeladene Element ist nicht der erwartete Dateityp. Bitte laden Sie eine gültige CSV-Datei hoch.',
					'error'
				);
				return;
			}

			acceptedFiles.forEach((file) => {
				const reader = new FileReader();
				reader.onload = () => {
					if (reader.result) {
						try {
							const csvContent = reader.result.toString();
							let trips = parseCsvToTrips(csvContent);
							trips = addMissingInformation(trips);
							console.log('Parsed Trips:', trips);
							createTripApi(trips);
						} catch (error: any) {
							triggerSnackbar(
								'Fehler beim Parsen der CSV-Datei: ' + error.message,
								'error'
							);
						}
					}
				};

				reader.onerror = () => {
					triggerSnackbar('Die Datei konnte nicht gelesen werden.', 'error');
				};

				reader.readAsText(file, 'utf-8');
			});
		},
		[triggerSnackbar]
	);

	const {getRootProps, getInputProps, isDragActive} = useDropzone({
		onDrop,
		accept: {
			'text/csv': ['.csv'], // Standard CSV MIME type
			'application/vnd.ms-excel': ['.csv'] // Excel CSV MIME type
		},
		maxSize: 2000, // Maximum size: 2KB (2000 bytes)
		maxFiles: 1 // Allow only one file
	});

	return (
		<div
			{...getRootProps()}
			style={{
				border: '1px dashed #ccc',
				borderRadius: '4px',
				padding: '10px', // Adjust padding for a clean look
				textAlign: 'center',
				cursor: 'pointer',
				width: '150px', // Match width with button
				height: '50px',
				fontSize: '14px',
				backgroundColor: '#f9f9f9',
				display: 'flex', // Use flexbox for centering
				alignItems: 'center', // Center text vertically
				justifyContent: 'center' // Center text horizontally
			}}
		>
			<input {...getInputProps()} />
			{isDragActive ? <p>Datei hier ablegen...</p> : <p>CSV Datei hochladen</p>}
		</div>
	);
};

export default CsvFileUploadTripCreation;
