import React from 'react';
import {Button, Modal, ModalProps, useSnackbar} from '@easerill/pixida-group-ui';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
	Backdrop,
	CircularProgress,
	Autocomplete,
	TextField as MuiTextField,
	Stack
} from '@mui/material';
import {MobileDateTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {User} from '../../redux/Auth';
import {Dayjs} from 'dayjs';
import useStateAuth from '../../hooks/state/useStateAuth';
import {Vehicle, CreateTripRequest, Trip} from '@sumo/types';
import {createTrip} from '../../api/trip';

/**
 * Interface for the TripForm state
 */
interface TripForm {
	tripName: string;
	vehicleId: string;
	startTime: Dayjs | null;
	endTime: Dayjs | null;
}

/**
 * Props for the TripCreationModal component
 */
export type CreateTripProps = {
	onCancel: () => void;
	onSave: () => void;
	vehicles: Vehicle[];
	availableUsers: User[];
	setTrips: React.Dispatch<React.SetStateAction<Trip[]>>;
} & Omit<ModalProps, 'title' | 'onClose'>;

/**
 * Component to create a trip
 * @param {TripCreationModalProps} props - The props for the CreateTrip component.
 * @returns The CreateTrip component.
 */
export const CreateTrip = ({onCancel, onSave, open, vehicles, availableUsers, setTrips}: CreateTripProps) => {
	const {user} = useStateAuth();
	const {triggerSnackbar} = useSnackbar();

	const [tripForm, setTripForm] = React.useState<TripForm>({
		tripName: '',
		vehicleId: '',
		startTime: null,
		endTime: null
	});

	const [selectedUser, setSelectedUser] = React.useState<User | null>(user ?? null);
	const [selectedVehicle, setSelectedVehicle] = React.useState<Vehicle | null>();
	const [loading, setLoading] = React.useState(false);

	/**
	 * Handles the cancel button click
	 */
	const handleCancelClick = () => {
		if (onCancel) {
			onCancel();
		}
	};

	/**
	 * Handles the save button click
	 */
	const handleSaveClick = () => {
		const {tripName, startTime, endTime} = tripForm;

		if (!selectedUser || !tripName || !selectedVehicle || !startTime || !endTime) {
			triggerSnackbar('Bitte füllen Sie alle Felder des Formulars aus', 'error');
			return;
		}

		if (startTime.isAfter(endTime)) {
			triggerSnackbar(
				'Startdatum und -uhrzeit dürfen nicht nach Enddatum und -uhrzeit liegen.',
				'error'
			);
			return;
		}

		const tripData: CreateTripRequest = {
			driverId: selectedUser.username ?? '',
			driverName: selectedUser.name,
			name: tripName.trim(),
			vehicleSerial: selectedVehicle.serial,
			customerId: selectedVehicle.customerId,
			assignedVehicle: selectedVehicle.number,
			startTime: startTime.toISOString(),
			endTime: endTime.toISOString()
		};

		createTripApi(tripData);
	};

	const resetForm = () => {
		setTripForm({
			tripName: '',
			vehicleId: '',
			startTime: null,
			endTime: null
		});
		setSelectedUser(user ?? null);
	};

	/**
	 * Calls the API to create a new trip
	 * @param tripData the data of the trip
	 */
	const createTripApi = (tripData: CreateTripRequest) => {
		setLoading(true);
		triggerSnackbar('Ihre Fahrt wird erstellt. Dies kann einige Sekunden dauern.', 'info');

		createTrip(tripData)
			.then((newTrip: Trip) => {
				setTrips((prevTrips: Trip[]) => [...prevTrips, newTrip]);
				triggerSnackbar('Die Fahrt wurde erfolgreich erstellt.', 'success');
			})
			.catch((err) => {
				if (err.message === "Network Error" || err.code === "ERR_NETWORK") {
					triggerSnackbar(
						`Das Erstellen der Fahrt dauert aufgrund der großen Datenmenge etwas länger. Bitte laden Sie die Seite in Kürze neu, um die Fahrt anzuzeigen.`,
						'info'
					);
				} else {
					let message = '';
					if (err?.response?.data?.error) {
						message = err.response.data.error;
						if (message === 'no data for time') {
							message = 'Für diesen Zeitraum sind keine Daten des Fahrzeugs vorhanden.'
						}
					}
					triggerSnackbar(
						`Es gab einen Fehler beim Erstellen der Fahrt. ${message}`,
						'error'
					);
				}
			})
			.finally(() => {
				setLoading(false);
				onSave();
				resetForm();
			});
	};

	const handleStartTimeChange = (newValue: Dayjs | null) => {
		setTripForm((prev) => ({
		  ...prev,
		  startTime: newValue,
		  // If endTime is null or earlier than the new startTime, update endTime to match startTime
		  endTime: !prev.endTime || (newValue && newValue.isAfter(prev.endTime))
			? newValue
			: prev.endTime,
		}));
	  };
	
	  const handleEndTimeChange = (newValue: Dayjs | null) => {
		setTripForm((prev) => ({ ...prev, endTime: newValue }));
	  };

	return (
		<Modal open={open} onClose={handleCancelClick} title="Create a Trip">
			<Backdrop
				sx={{
					color: '#fff',
					/**
					 * Sets the z-order of a positioned element
					 * @param {object} theme - The MUI theme object.
					 * @returns {number} The calculated z-index value for the backdrop.
					 */
					zIndex: (theme) => theme.zIndex.drawer + 1
				}}
				open={loading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<Stack
					spacing={3}
					sx={{maxWidth: 600, margin: '0 auto', padding: 2}}
					alignItems="stretch"
				>
					<Autocomplete
						id="user-selector"
						value={selectedUser}
						autoHighlight
						onChange={(event, newValue) => setSelectedUser(newValue)}
						options={availableUsers}
						getOptionLabel={(option) => `${option.name} (${option.email})` || 'Unknown'}
						isOptionEqualToValue={(option, value) =>
							value !== null && option.username === value.username
						}
						renderInput={(params) => (
							<MuiTextField {...params} label="Fahrer auswählen" required fullWidth />
						)}
						fullWidth
					/>
					<Autocomplete
						id="vehicle-selector"
						value={selectedVehicle}
						autoHighlight
						onChange={(event, newValue) => setSelectedVehicle(newValue)}
						options={vehicles}
						getOptionLabel={(option) => option.number}
						isOptionEqualToValue={(option, value) =>
							value !== null && option.serial === value.serial
						}
						renderInput={(params) => (
							<MuiTextField
								{...params}
								label="Fahrzeug auswählen"
								required
								fullWidth
							/>
						)}
						fullWidth
					/>
					<MuiTextField
						label="Fahrtenname"
						value={tripForm.tripName}
						onChange={(e) =>
							setTripForm((prev) => ({...prev, tripName: e.target.value}))
						}
						required
						fullWidth
					/>
					<MobileDateTimePicker
						label="Startdatum und -uhrzeit"
						value={tripForm.startTime}
						onChange={handleStartTimeChange}
						ampm={false}
						format="DD/MM/YYYY HH:mm" 
					/>
					<MobileDateTimePicker
						label="Enddatum und -uhrzeit"
						value={tripForm.endTime}
						onChange={handleEndTimeChange}
						ampm={false}
						minDateTime={tripForm.startTime || undefined}
						maxDateTime={
							tripForm.startTime ? tripForm.startTime.add(1, 'day') : undefined
						  }
						openTo={tripForm.startTime ? 'hours' : 'day'}
						format="DD/MM/YYYY HH:mm" 
					/>
					<Stack direction="row" spacing={2} justifyContent="flex-end">
						<Button
							variation="info"
							text="Abbrechen"
							onClick={handleCancelClick}
							startIcon={<HighlightOffIcon />}
						/>
						<Button
							variation="success"
							text="Fahrt erstellen"
							onClick={handleSaveClick}
							startIcon={<CheckCircleOutlineIcon />}
						/>
					</Stack>
				</Stack>
			</LocalizationProvider>
		</Modal>
	);
};
